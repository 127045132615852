import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import SearchCard from '../SearchCard/SearchCard';
import "./Map.styles.css";
import ButtonShowBarList from '../ButtonShowBarList/ButtonShowBarList';
import { libraries } from './constants';
import api from '../../services/ApiService';
import CarruselListBares from '../CarruselListBares/CarruselListBares';
import FilterCard from '../FiltersBars/FilterCard';
import useUserLocation from '../../hooks/useUserLocation';
import { useUser } from '../../contexts/UserContext';
import { BarsMarkers, SelectedBarInfoWindow, UserLocationMarker } from './MapComponents';
import NavBar from '../Navbar/NavBar';
import MapUtils from '../../utils/MapUtils';
import useLoadBars from '../../hooks/useLoadBars';
import { LIST_BARES } from '../../constants';
import ActionButtons from '../ActionButtons/ActionButtons';
import GustosYPreferencias from '../GustosYPreferencias/GustosYPreferencias';
import SplashScreen from '../SplashScreen/SplashScreen';
import UserFiltrosDto from '../../dto/userFiltrosDto';
import { useLoading } from '../../hooks/useLoading';
import CiudadSelector from '../CiudadSelector/CiudadSelector';
import Login from '../ButtonLogin/Login';
import Register from '../ButtonLogin/Register';
import SinSesion from '../SinSesionPopUp/SinSesion';
import { useMap } from '../../contexts/MapContext';
import { getAllBarsFromCache, saveBarsToCache } from '../../indexedDB/barsCacheService';
import { getOnboardingRecienAcabado, setOnboardingStatus } from '../OnboardingScreen/onboardingStatus';
//import { debounce, isEqual } from 'lodash';


const containerStyle = {  width: '100%', height: '100%' };

const mapStyle = { width: '100%', height: '100%' };

const mapOptions = {
  disableDefaultUI: true,
  styles: [ 
    { featureType: 'poi.business', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', elementType: 'labels.icon', stylers: [{ visibility: 'off' }],}
  ],
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  clickableIcons:false,
};

const directionsRendererOptions = { markerOptions: { visible:false } }

const Map = () => {
  const { config, user, selectedBar, setSelectedBar, aplicarTodosFiltros, setAplicarTodosFiltros, filtros, authenticated } = useUser();
  const { userLocation, setUserLocation, initialLocation, modalSinUbicacionVisible, setModalSinUbicacionVisible, handleReubicar, handleSelectCity } = useUserLocation();
  const {  setBounds, topBars, setTopBars, restBars, setRestBars, baresTodos, setBaresTodos } = useMap();
  const [zoomLevel, setZoomLevel] = useState(config.distZoom || 16);
  const { error } = useLoadBars(userLocation, zoomLevel, user?.tastesAndPreferences);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [offCanvas, setOffCanvas] = useState(false);
  const [map, setMap] = useState(null);
  const [showCenterButton, setShowCenterButton] = useState(false);
  const [filtrosRapidos, setFiltrosRapidos] = useState([]);

  const mapContainerRef = useRef(null);

  const [showSearchCard, setShowSearchCard] = useState(false);
  const [showFilterCard, setShowFilterCard] = useState(false);
  const [showGypModal, setShowGypModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [viewLocation, setViewLocation] = useState();
  const { stopLoading, isLoading: isFirstLoading } = useLoading();
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isOpenSinSesion, setIsOpenSinSesion] = useState(false);

  // Actualiza la ubicación inicial cuando se obtiene la ubicación del usuario
  useEffect(() => {
    if (userLocation && !viewLocation) {
      const userLocationIsDefault = 
        userLocation.lat === initialLocation.lat && 
        userLocation.lng === initialLocation.lng;
      
      // Inicializa viewLocation solo una vez
      if (!userLocationIsDefault){
        setViewLocation(userLocation); 
        console.log("Map stopLoading 1 - userLocationIsDefault");
        stopLoading();
      }
    }
  }, [userLocation, viewLocation]);

  const filterNewOrUpdatedBars = (baresTodos, cachedBars) => {
    // Crear un objeto cacheMap donde las claves son los IDs de los bares
    const cachedMap = cachedBars.reduce((acc, bar) => {
      acc[bar.id] = bar;
      return acc;
    }, {});
  
    // Filtrar los bares nuevos o actualizados comparándolos con los de la cache
    return baresTodos.filter(bar => {
      const cachedBar = cachedMap[bar.id]; // Acceder al objeto directamente
      return !cachedBar || JSON.stringify(cachedBar) !== JSON.stringify(bar);
    });
  };

  useEffect(() => {
    const updateBarsCache = async () => {
      try {
        // Obtener los datos existentes de IndexedDB
        const cachedBars = await getAllBarsFromCache();
    
        // Filtrar solo los bares nuevos o actualizados
        const barsToSave = filterNewOrUpdatedBars(baresTodos, cachedBars);
    
        if (barsToSave.length > 0) {
          await saveBarsToCache(barsToSave); // Guardar solo los necesarios
        }
      } catch (error) {
        console.error("Error actualizando el cache de bares:", error);
      }
    };
  
    if (baresTodos.length > 0) {
      updateBarsCache();
    }
    
  }, [baresTodos]);

  // Función para verificar si el onboarding acaba de finalizar
  useEffect(() => {
    if (!authenticated && getOnboardingRecienAcabado()){
      mostrarRegistro();
    }
  }, []);

  // Detección de dispositivos móviles
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Manejo de la visibilidad de las tarjetas en función del dispositivo
  useEffect(() => {
    if (isMobile) {
      setShowSearchCard(false);
      setShowFilterCard(false);
    } else {
      setShowSearchCard(true);
      setShowFilterCard(true);
    }
  }, [isMobile]);

  const toggleSearchCard = () => setShowSearchCard(!showSearchCard);
  const toggleFilterCard = () => setShowFilterCard(!showFilterCard);
  const toggleGypModal = () => authenticated ? setShowGypModal(!showGypModal) : setIsOpenSinSesion(true);

  useEffect(() => {

    if (!isFirstLoading && map) {
      console.log("Map stopLoading 1 isFirstLoading", isFirstLoading);
      console.log("Map stopLoading 1");
      
      stopLoading();
    }
  }, [isFirstLoading, map]);

  // Actualiza la lista de bares visibles cuando cambian los filtros o el nivel de zoom
  useEffect(() => {
    console.log("updateVisibleBars filtrosRapidos", filtrosRapidos);
    console.log("updateVisibleBars baresTodos", baresTodos);

    updateVisibleBars(baresTodos);
  }, [baresTodos, filtrosRapidos]);

  const updateVisibleBars = (bars) => {
    console.log("updateVisibleBars");
    const numBares = config.numBares || 10;
    if (map && userLocation) {
      const bounds = map.getBounds();
      const barsVisible = bars.filter((bar) => bounds?.contains(bar.location));
      let newTopBars, newRestBars;
      
      if (filtrosRapidos?.length > 0) {
        const baresFiltrados = barsVisible.filter(bar => bar.types.slice(0, 3).some(type => filtrosRapidos.includes(type)));
        newTopBars = baresFiltrados.slice(0, numBares);
        newRestBars = baresFiltrados.slice(numBares);
        setRestBars(prevRestBars => {
            // Filtrar los bares anteriores que coincidan con los filtros
            const filtradosPrevRestBars = prevRestBars.filter(bar => bar.types.some(type => filtrosRapidos.includes(type)));
            
            // Crear un Set con los IDs de los bares filtrados actuales
            const restBarIds = new Set(filtradosPrevRestBars.map(bar => bar._id));
            
            // Añadir los nuevos bares filtrados si no están ya en el Set
            newRestBars.forEach(bar => {
                if (!restBarIds.has(bar._id)) {
                    restBarIds.add(bar._id);
                    filtradosPrevRestBars.push(bar);
                }
            });

            return filtradosPrevRestBars;
        });
      } else {
        newTopBars = barsVisible.slice(0, numBares);
        newRestBars = barsVisible.slice(numBares);
        
        setRestBars(prevRestBars => {
            const restBarIds = new Set(prevRestBars.map(bar => bar._id));
            newRestBars.forEach(bar => {
                if (!restBarIds.has(bar._id)) {
                    restBarIds.add(bar._id);
                    prevRestBars.push(bar);
                }
            });
            return prevRestBars;
        });
      }
      setTopBars(newTopBars);
    }
  };

  useEffect(() => {
    
      const aplicarFiltrosAsync = async () => {
        if (aplicarTodosFiltros) {
          console.log("aplicarFiltrosAsync");
          const userFiltrosDto = new UserFiltrosDto(userLocation, config.distZoom, user.tastesAndPreferences, filtros);
          try {
              const baresFiltrados = await api.bars.aplicarFiltros(userFiltrosDto);
              console.log("setBaresTodos aplicarFiltrosAsync");
              
              setBaresTodos(baresFiltrados);
              updateVisibleBars(baresFiltrados);
          } catch (error) {
              console.error("Error al aplicar filtros:", error);
          } finally {
              setAplicarTodosFiltros(false);
          }
        }
      };

      aplicarFiltrosAsync();

  }, [aplicarTodosFiltros]);

  useEffect(() => {
    if (mapContainerRef.current) {
      const setMapContainerHeight = () => {
        const windowHeight = window.innerHeight;
        const mapContainer = document.querySelector('.map-container');
        if (mapContainer) {
          mapContainer.style.height = `${windowHeight}px`;
        }
      };

      setMapContainerHeight(); 
    
      // Llama a la función cada vez que cambia el tamaño de la ventana
      window.addEventListener('resize', setMapContainerHeight);
    
      return () => {
        window.removeEventListener('resize', setMapContainerHeight); 
      };
    }
  }, [mapContainerRef.current]);

  const handleBarSelect = useCallback((bar) => {
    setSelectedBar(bar);
    if (map && bar.location) {
      const newPosition = { lat: bar.location.lat, lng: bar.location.lng };
      map.panTo(newPosition);
  
      const offsetY = -window.innerHeight / 7;
      map.panBy(0, offsetY);
    }
  }, [map, setSelectedBar]);

  const handleMapIdle = () => {
    if (map && userLocation) {
      const mapBounds = map.getBounds();
      console.log("seteamos bounds 2");
      setBounds(map.getBounds());
      const isUserLocationInsideBounds = mapBounds?.contains(userLocation);
      setShowCenterButton(!isUserLocationInsideBounds);
      updateVisibleBars(baresTodos);
    }
  };

  const handleCenterButtonClick = () => {
    if (map && userLocation) {
      map.panTo(userLocation, { animation: window.google.maps.Animation.BOUNCE });
      setShowCenterButton(false);
    }
  };

  const handlePlaceSelect = (place) => {
    const newLocation = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    //setUserLocation(newLocation);
    setViewLocation(newLocation);
    setSearchResults([]);
    setSelectedLocation(place);
  };

  const handleMenuButtonClick = () => {
    setOffCanvas(!offCanvas);
  };

  const handleMapLoad = (map) => {
    console.log("seteamos bounds 3");
    setMap(map);
    setBounds(map.getBounds());
    // Escucha el evento de zoom en el mapa
    map.addListener('zoom_changed', () => {
      const newZoomLevel = map.getZoom();
      setZoomLevel(newZoomLevel);
    });
  
    //stopLoading(false);
    
  };

  const handleFiltrosRapidos = (nuevoFiltro) => {
    let filtro = MapUtils.asignarFiltros(nuevoFiltro);
    setFiltrosRapidos(filtro);
  }

  const mostrarRegistro = () => {
    console.log("REGISTRO mostrarRegistro");
		setShowRegister(true);
    setShowLogin(false);
    setOnboardingStatus('false');
  };

	const handleChangeAuth = () => {
    console.log("REGISTRO handleChangeAuth");
    setShowLogin(!showLogin);
    setShowRegister(!showRegister);
  };

  const handleCloseAuth = () => {
    console.log("REGISTRO handleCloseAuth");
    setShowLogin(false);
    setShowRegister(false);
  };

  const handleCloseSinSesion = () => {
    setIsOpenSinSesion(false);
  }

  if (isFirstLoading) {
    return (
      <>
        <SplashScreen />
        <CiudadSelector
          isVisible={modalSinUbicacionVisible}
          onClose={() => handleSelectCity(null)}
          onSelectCity={handleSelectCity}
          onSelectReubicar={handleReubicar}
        />
      </>
      
    );
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={libraries}>
      <div className="map-container" ref={mapContainerRef}>
        <GoogleMap mapContainerStyle={containerStyle} center={viewLocation || userLocation} zoom={zoomLevel} style={mapStyle} options={mapOptions} onLoad={handleMapLoad} onIdle={handleMapIdle}>
          <NavBar handleBarSelect={handleBarSelect} toggleSearchCard={toggleSearchCard} toggleFilterCard={toggleFilterCard} />
          {/* Marcador de la ubicación del usuario */}
          <UserLocationMarker userLocation={userLocation} />

          {/* Marcadores de bares */}
          {map && topBars && restBars && 
            <>
            <BarsMarkers bars={restBars} handleBarSelect={handleBarSelect} isTop={false}/>
            <BarsMarkers bars={topBars} handleBarSelect={handleBarSelect} isTop={true}/>
            </>
          }

          {/* Informacion en bares */}
          {selectedBar && <SelectedBarInfoWindow selectedBar={selectedBar} setSelectedBar={setSelectedBar} />}
          
          <ActionButtons
              onFilterClick={toggleFilterCard}
              onSearchClick={toggleSearchCard}
              onGypClick={toggleGypModal}
          />

          <div className='filters-container'>
            {/* Card de búsqueda */}
            {showSearchCard  && (
              <SearchCard
                onPlaceSelect={handlePlaceSelect}
                showCenterButton={showCenterButton}
                handleCenterButtonClick={handleCenterButtonClick}
                handleBarSelect={handleBarSelect}
                toggleSearchCard={toggleSearchCard} 
              />
            )}

            {/* Filtro de bares */}
            {showFilterCard && (
              <FilterCard
                handleFiltrosRapidos={handleFiltrosRapidos} 
                toggleFilterCard={toggleFilterCard} />
            )}
          </div>

          {showGypModal && <GustosYPreferencias onClose={toggleGypModal} />}

          {/* <AdComponent1 /> */}
          {/* <AdPlaceholder/> */}
          

          {/* Card con el botón en la parte inferior izquierda */}
          <ButtonShowBarList onClick={handleMenuButtonClick}/>

          {/* Tarjetas de bares dentro del mapa */}
          <CarruselListBares 
            topBars={topBars} 
            showCarruselList={offCanvas} 
            titulo= {LIST_BARES.cercanos}
            onClose={handleMenuButtonClick}
            handleBarSelect={handleBarSelect}
          ></CarruselListBares>

          {/* {isLoadingBares && <Loading />} */}

          {showLogin && <Login
            handleChangeForm={handleChangeAuth}
            handleClose={handleCloseAuth}
          />}
          
          {showRegister && <Register
              handleChangeForm={handleChangeAuth}
              handleClose={handleCloseAuth}
          />}

          {isOpenSinSesion && <SinSesion onClose={handleCloseSinSesion} />}

        </GoogleMap>
      </div>
    </LoadScript>
  );
};


export default Map;
