import React from 'react';
import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import OpinionesSection from './OpinionesSection';
import InformacionSection from './InformacionSection';
import MisOpinionesSection from './MisOpinionesSection';

const BarDetallesTabs = ({ iteracionesFiltradas, bar, iteraciones, ratingsUser, ratingsUserBar, abrirNotificaciones }) => {
    return (
        <Tabs aria-label="bar-detalles-tabs" variant="underlined" defaultSelectedKey="info" classNames={{
            cursor: "bg-primary",
        }}>
            <Tab key="datosUsers" title="Opiniones">
                <Card>
                    <CardBody>
                        <OpinionesSection 
                            iteracionesFiltradas={iteracionesFiltradas}
                            abrirNotificaciones={abrirNotificaciones}
                        />
                    </CardBody>
                </Card>
            </Tab>
            <Tab key="info" title="Información">
                <Card>
                    <CardBody>
                        <InformacionSection 
                            bar={bar} 
                            iteraciones={iteraciones} 
                        />
                    </CardBody>
                </Card>
            </Tab>
            <Tab key="ratingsUser" title="Mis opiniones">
                <Card>
                    <CardBody>
                        <MisOpinionesSection 
                            ratingsUser={ratingsUser} 
                            ratingsUserBar={ratingsUserBar}
                            abrirNotificaciones={abrirNotificaciones}
                        />
                    </CardBody>
                </Card>
            </Tab>
        </Tabs>
    );
};

export default BarDetallesTabs;
