import React, { useState } from 'react'
import { Listbox, ListboxItem } from '@nextui-org/react';
import masFiltrosIcon from '../../assets/icons/mas.png';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import { FILTROS } from '../../constants/index';
import FilterModal from '../FilterModal/FilterModal';
import { useTemasNotificaciones } from '../../contexts/TemasNotificacionesContext';

const FiltersBars = ({barsByFilter}) => {

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const { t } = useTranslation();
    const { setFiltros } = useUser();
    const { temasFiltroRapido } = useTemasNotificaciones();

    const toggleFilterModal = () => {
        setIsFilterModalOpen(prev => !prev);
    };

    const handleFilters = (filters) => {

        const filtersActivos = Array.from(filters);
        const filtersArray = filtersActivos.filter(key => key !== FILTROS.masFiltros);

        const selectedObjects = filtersArray.map(key => {
            const foundFilter = temasFiltroRapido.find(filtro => filtro.subtemas[0].id === key);
            return {
                name: foundFilter?.subtemas[0]?.id,
                style: foundFilter?.id
            };
        });

        if (Array.isArray(selectedObjects)){
            setFiltros(selectedObjects);
            barsByFilter(filtersArray);
        }
                
        if (filters.currentKey === FILTROS.masFiltros 
            && filtersActivos.includes(FILTROS.masFiltros)){
            toggleFilterModal();
        }

    }

    return (
        <>
            <Listbox 
                color="primary" 
                variant="shadow" 
                aria-label="Menu filters"
                selectionMode="multiple"
                onSelectionChange={handleFilters}
                classNames={{
                    list: "p-0",
                }}
                >
                {temasFiltroRapido.map((filtro) => (
                    <ListboxItem
                        key={filtro.subtemas[0].id}
                        startContent={<img src={filtro.subtemas[0].img} alt="Search icon" className="w-6 h-6 mr-2" />}
                    >
                        {filtro.subtemas[0].title}
                    </ListboxItem>
                ))}
                {/* <ListboxItem
                    key={FILTROS.masFiltros}
                    startContent={<img src={masFiltrosIcon} alt="Search icon" className="w-6 h-6 mr-2" />}
                >
                    {t('filtro.masFiltros')}
                </ListboxItem> */}
            </Listbox>

            <FilterModal isOpen={isFilterModalOpen} onClose={toggleFilterModal} />
        </>
    );
}

export default FiltersBars