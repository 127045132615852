// onboardingStatus.js
export const getOnboardingStatus = () => {
  return localStorage.getItem('onboardingCompleted') === 'true';
};

export const getOnboardingRecienAcabado = () => {
  return localStorage.getItem('onboardingRecienAcabado') === 'true';
};
  
export const setOnboardingStatus = (status) => {
  localStorage.setItem('onboardingCompleted', status);
  localStorage.setItem('onboardingRecienAcabado', status);
};
  