import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';

// Creamos el contexto
const MapContext = createContext();

// Hook personalizado para facilitar el uso del contexto
export const useMap = () => useContext(MapContext);

// Proveedor del contexto
export const MapProvider = ({ children }) => {
  const [baresTodos, setBaresTodos] = useState([]);
  const [baresVisibles, setBaresVisibles] = useState([]);
  const [topBars, setTopBars] = useState([]);
  const [restBars, setRestBars] = useState([]);
  const [estaCargandoBares, setEstaCargandoBares] = useState(false);
  
  // Estado del mapa
  const [bounds, setBounds] = useState(null);

    // Calcula el polígono basado en bounds
    const calculatePolygon = useCallback(() => {
      if (!bounds) return null;
  
      const ne = bounds.getNorthEast(); // Esquina superior derecha
      const sw = bounds.getSouthWest(); // Esquina inferior izquierda
  
      return {
        north: ne.lat(),
        south: sw.lat(),
        east: ne.lng(),
        west: sw.lng(),
      };
    }, [bounds]);

  // Calcula y actualiza el polígono si cambia bounds
  const polygon = useMemo(() => calculatePolygon(), [calculatePolygon]);

  useEffect(() => {
    if (polygon) {
      console.log('Polygon updated:', polygon);
    }
  }, [polygon]);

  useEffect(() => {
    // Aquí podrías hacer una llamada a la API para obtener todos los bares
    // y establecer el estado inicial cuando el componente se monta
    /*const fetchBares = async () => {
      setEstaCargandoBares(true);
      try {
        const response = await fetch('/api/bares'); // Cambia esta URL a tu endpoint
        const data = await response.json();
        
        setBaresTodos(data);
        setBaresVisibles(data); // Inicialmente mostramos todos los bares en el mapa
        // Aquí podrías filtrar para determinar topBars y restBars
        setTopBars(data.filter(bar => bar.rating > 4)); // Ejemplo de filtro
        setRestBars(data.filter(bar => bar.rating <= 4));
      } catch (error) {
        console.error('Error fetching bares:', error);
      } finally {
        setEstaCargandoBares(false);
      }
    };*/

    calculatePolygon();
    //fetchBares();
  }, [calculatePolygon]);

  // Proporcionamos los valores y métodos necesarios a los componentes hijos
  return (
    <MapContext.Provider
      value={{
        baresTodos,
        setBaresTodos,
        baresVisibles,
        setBaresVisibles,
        topBars,
        setTopBars,
        restBars,
        setRestBars,
        estaCargandoBares,
        setEstaCargandoBares,
        bounds,
        setBounds,
        polygon
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
