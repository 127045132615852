import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Textarea } from '@nextui-org/react';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import IconosTemas from './IconosTemas';
import { obtenerListadoTemas } from '../../utils/incidenciasUtils';
import UserRatingsDto from '../../dto/userRatingsDto';
import apiUserRatings from '../../services/ApiUserRatingService';
import NotificationIcon from './NotificationIcon';
import imgGracias from '../../assets/icons/gracias.png';
import api from '../../services/ApiService';
import UserInfoBarDto from '../../dto/userInfoBarDto';
import { useUser } from '../../contexts/UserContext';
import './NotificationIcon.scss';

const SubtemasPopUp = ({ isOpen, closeSubtemas, temaPrincipal, selectedBar, opinionesDelBar }) => {
  
	const { user, recalcularRatings, guardarTagsEnBar } = useUser();
	const [comment, setComment] = useState('');
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedSubtemas, setSelectedSubtemas] = useState([]);
	const { t } = useTranslation();
	let idComentarios = "comentarios";
	

	const listTemasPrincipales = obtenerListadoTemas(temaPrincipal);

	const tieneAlgoEscrito = () => {
		return comment.trim() !== '';
	};

	const handleGuardarRatingsYComentarios = async (userRating) => {
        const response = await apiUserRatings.guardarRatingsYComentarios(userRating);
        if (response.status == 200) {
            recalcularRatings();
			guardarTagsEnBar(userRating.tags);
        }
    }
	
	const handleGuardarInfoBar = async (userInfoBar) => {
        const response = await api.bars.guardarInfoBar(userInfoBar);
        if (response.status == 200) {
        }
    }

	const enviarInformacion = async () => {
		if (temaPrincipal.id === idComentarios){

			if (tieneAlgoEscrito()){
				const userRating = new UserRatingsDto(user, selectedBar,comment, null, null);
				try {
					await handleGuardarRatingsYComentarios(userRating);
					setShowConfirmation(true);
					setTimeout(() => {
						setComment('');
						closeSubtemas();
					}, 2000);
				} catch (error) {
					console.error('Error al guardar la calificación:', error);
				}
			}

		} else if (selectedSubtemas && selectedSubtemas.length > 0) {

			const tags = selectedSubtemas.map(subtema => ({
				key: temaPrincipal.id,
				value: subtema
			}));

			const userRating = new UserRatingsDto(user, selectedBar, null, null, tags);
			const userInfoBar = new UserInfoBarDto(user,selectedBar,temaPrincipal,selectedSubtemas);

			try {
				await handleGuardarRatingsYComentarios(userRating);
				await handleGuardarInfoBar(userInfoBar);
				setShowConfirmation(true);
				setTimeout(() => {
					closeSubtemas();
				}, 2000);
			} catch (error) {
				console.error('Error al guardar la información:', error);
			}
		
		}
		
	}
	
	const handleSubtemaClick = (subtema) => {
		// Verificar si el subtema ya está seleccionado
		const index = selectedSubtemas.indexOf(subtema.id);
		if (index === -1) {
			// Si no está seleccionado, agregarlo a la lista de subtemas seleccionados
			setSelectedSubtemas([...selectedSubtemas, subtema.id]);
		} else {
			// Si ya está seleccionado, eliminarlo de la lista de subtemas seleccionados
			const updatedSubtemas = [...selectedSubtemas];
			updatedSubtemas.splice(index, 1);
			setSelectedSubtemas(updatedSubtemas);
		}
	};

	return (
		<>
		<Modal isOpen={isOpen} onOpenChange={closeSubtemas} scrollBehavior="inside">
			<ModalContent>
			{(onClose) => (
				<>
				<ModalHeader className="flex flex-col gap-1 pb-0">{temaPrincipal.title}</ModalHeader>
				<ModalBody>
					{temaPrincipal.id === idComentarios ? (
						<div>
							<Textarea
								label={t('notificaciones.comentario')}
								placeholder='Escribe tu comentario...'
								value={comment}
								onChange={(e) => setComment(e.target.value)}
							></Textarea>
						</div>
					) : (
						<div className="contenedor-subtemas">
						{listTemasPrincipales.map((temaPrincipal, index) => (
							<IconosTemas 
								tema={temaPrincipal} 
								key={index}
								onTemaClick={handleSubtemaClick}
								opinionesDelBar={opinionesDelBar}
							/>
						))}
						</div>
					)}	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onPress={enviarInformacion}>
					{t('informar')}
					</Button>
				</ModalFooter>
				</>
			)}
			</ModalContent>
		</Modal>

		{/* Icono de confirmación con animación */}
		{showConfirmation && (
			<NotificationIcon imageUrl={imgGracias} />
		)}
		</>
	)
}

export default SubtemasPopUp;