import { Alert } from 'bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { useLoading } from './useLoading';

const useUserLocation = () => {
    const initialLocation = { lat: 40.4281882, lng: -3.7217989 };
    const [userLocation, setUserLocation] = useState(initialLocation);
    const [loadingUserLocation, setLoadingUserLocation] = useState(true);
    const [error, setError] = useState(null);
    const [modalSinUbicacionVisible, setModalSinUbicacionVisible] = useState(false);
    const [reubicar, setReubicar] = useState(false);
    const { stopLoading } = useLoading();
    
    // Recuperar el valor de localStorage o establecer el estado inicial
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState(() => {
        const ciudadGuardada = localStorage.getItem("ciudadSeleccionada");
        return ciudadGuardada ? JSON.parse(ciudadGuardada) : null;
    });

    // Guardar en localStorage cada vez que ciudadSeleccionada cambie
    useEffect(() => {
        localStorage.setItem("ciudadSeleccionada", JSON.stringify(ciudadSeleccionada));
    }, [ciudadSeleccionada]);

    // Function to handle location updates
    const handlePosition = useCallback((position) => {
        console.log("USEUSERLOCATION - HANDLEPOSITION");
        const { latitude, longitude } = position.coords;
        const newLocation = { lat: latitude, lng: longitude };
        
        setUserLocation(newLocation); 
        setLoadingUserLocation(false);
        setCiudadSeleccionada(null);
    }, []);

    // Function to handle errors
    const handleError = useCallback((error) => {
        console.log("USEUSERLOCATION - HANDLEERROR");
        console.log("USEUSERLOCATION - HANDLEERROR - ciudadSeleccionada", ciudadSeleccionada);
        //setCiudadSeleccionada(null);
        if (!ciudadSeleccionada) {
            setError(`Error: ${error.message}`);
            console.error('Geolocation error:', error);
            setLoadingUserLocation(false);
            setModalSinUbicacionVisible(true);            
        } else {
            setUserLocation(ciudadSeleccionada);
            setLoadingUserLocation(false);
        }
    }, []);

    // Función para manejar la selección de ciudad
    const handleSelectCity = (city) => {
        const { latitude, longitude } = city.coords
            ? city.coords
            : city;
        const newLocation = { lat: latitude, lng: longitude };
        
        setCiudadSeleccionada(newLocation)
        setUserLocation(newLocation); 
        setModalSinUbicacionVisible(false);
        
        console.log("UseUserLocation stopLoading 1 - handleSelectCity");
        stopLoading();
    };

    const handleReubicar = () => {
        console.log("handleReubicar");
        setReubicar(true);
        setModalSinUbicacionVisible(false);
        setCiudadSeleccionada(null);
        window.location.reload();
    };

    useEffect(() => {
        let watchId = null;
        setLoadingUserLocation(true);
        setReubicar(false);
        console.log("USEUSERLOCATION - ENTRA");

        if (navigator.geolocation) { 
            console.log("USEUSERLOCATION - TRUE");
            watchId = navigator.geolocation.watchPosition(
                handlePosition,
                handleError,
                {
                    enableHighAccuracy: true, // Alta precisión
                    timeout: 10000, // Timeout ajustado para actualizaciones más frecuentes
                    maximumAge: 0 // Desactiva el almacenamiento en caché
                }
            );
        } else {
            console.log("USEUSERLOCATION - FALSE");
            handleError('Geolocation is not supported by this browser.');
        }

        return () => {
            if (watchId !== null) {
                navigator.geolocation.clearWatch(watchId);
            }
            setLoadingUserLocation(false);
        };
    }, [handlePosition, handleError, reubicar, ciudadSeleccionada]);

    return { userLocation, setUserLocation, error, loadingUserLocation, initialLocation, modalSinUbicacionVisible, setModalSinUbicacionVisible, 
        handleSelectCity, handleReubicar };
};

export default useUserLocation;
