import { useCallback, useEffect, useRef, useState } from 'react';
import api from '../services/ApiService';
import { useUser } from '../contexts/UserContext';
import UserFiltrosDto from '../dto/userFiltrosDto';
import { useMap } from '../contexts/MapContext';
import debounce from 'lodash/debounce';
import useUserLocation from './useUserLocation';


const useLoadBars = (userLocation, zoomLevel, userPreferences) => {
  const [error, setError] = useState(null);
  const { filtros } = useUser();
  const { polygon, setBaresTodos, setEstaCargandoBares } = useMap();
  const { initialLocation } = useUserLocation();
  const abortControllerRef = useRef(null);

  const setLoading = useCallback((isLoading) => {
    setEstaCargandoBares(isLoading);
  }, []);
    
  // Combina bares actuales y nuevos eliminando duplicados
    const mergeBars = useCallback((currentBars, newBars) => {
      const barIds = new Set(currentBars.map(bar => bar._id));
      return [...currentBars, ...newBars.filter(bar => !barIds.has(bar._id))];
    }, []);

  const loadBars = useCallback(
    debounce(async () => {

      if (!userLocation) return;

      const userLocationIsDefault = 
        userLocation.lat === initialLocation.lat && 
        userLocation.lng === initialLocation.lng;
      
      if (userLocationIsDefault) return;

      // Cancela cualquier solicitud anterior
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const controller = new AbortController();
      abortControllerRef.current = controller;

      try {
        setLoading(true);

        console.log("loadBars -> SE LANZA");
        console.log(polygon);
        
        const userFiltrosDto = new UserFiltrosDto(userLocation, zoomLevel, userPreferences, filtros, polygon);
        console.log(userFiltrosDto);
        
        const baresCercanos = await api.bars.getBarsNearbyAdd(userFiltrosDto, { signal: controller.signal });
        console.log("setBaresTodos loadBars useLoadBars");
        setBaresTodos(prevBars => mergeBars(prevBars, baresCercanos));
        
      } catch (err) {
        if (err.name !== 'AbortError') {
          setError(err);
          console.error('Error loading bars:', err);
        }
      } finally {
        setLoading(false);
      }
    }, 500),
    [userLocation, zoomLevel, userPreferences, mergeBars, setBaresTodos, setLoading, polygon]
  )

  useEffect(() => {    
    if (userLocation && zoomLevel && polygon) {
      loadBars();
    }
    return () => {
      // Limpia la última llamada si el componente se desmonta
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      loadBars.cancel();
    };
  }, [userLocation, zoomLevel, loadBars, polygon]);

  return { error };
};

export default useLoadBars;