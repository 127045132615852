import { useEffect, useState } from "react";
import Map from "./Map/Map";
import SplashScreen from "./SplashScreen/SplashScreen";
import { useLoading } from "../hooks/useLoading";
import { useNavigate } from "react-router-dom";
import { getOnboardingStatus, setOnboardingStatus } from './OnboardingScreen/onboardingStatus';
import OnboardingScreen from "./OnboardingScreen/Onboarding";

const STATES = {
    SPLASH: "SPLASH",
    ONBOARDING: "ONBOARDING",
    MAP: "MAP",
};

function Home() {
    const [currentState, setCurrentState] = useState(STATES.SPLASH);
    const splashDuration = 2000;
    const { startLoading } = useLoading();
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            startLoading();
            const onboardingCompleted = getOnboardingStatus();

            if (onboardingCompleted) {
                navigate("/map"); // Redirige al mapa si el onboarding está completo
            } else {
                setCurrentState(STATES.ONBOARDING); // Cambia al estado de onboarding
            }
        }, splashDuration);

        return () => clearTimeout(timer);
    }, [navigate]);

    const handleOnboardingComplete = () => {
        setOnboardingStatus('true'); // Marca el onboarding como completado
        setCurrentState(STATES.MAP); // Cambia al estado del mapa
        navigate('/map'); 
    };

    return (
        <>
            {currentState === STATES.SPLASH && <SplashScreen />}
            {currentState === STATES.ONBOARDING && <OnboardingScreen onComplete={handleOnboardingComplete} />}
            {currentState === STATES.MAP && <Map />}
        </>
    );
}

export default Home;
