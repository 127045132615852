import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoWindowF } from '@react-google-maps/api';
import StarRating from '../StarRating/StarRating';
import TipoEtiqueta from '../TipoEtiqueta/TipoEtiqueta';
import utils from '../../utils/MapUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart, faBookmark as solidBookmark, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart, faBookmark as regularBookmark, faCommentDots, faPlusSquare, faXmark } from '@fortawesome/free-regular-svg-icons';
import './BarInfoPopUp.styles.css'; 
import { Toast, ToastBody, ToastHeader } from 'react-bootstrap';
import api from '../../services/ApiService';
import { Button} from '@nextui-org/react';
import { useUser } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Euro, MapPin, Star } from 'lucide-react';

const BarInfoPopUp = () => {
    const { t } = useTranslation();
    const { user, authenticated, selectedBar, setSelectedBar, guardarUser } = useUser();
    const [isLiked, setIsLiked] = useState(false);
    //const [isWished, setIsWished] = useState(false);
    const [showNotAuth, setShowNotAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated && user) {
            setIsLiked(user.favoriteBars.includes(selectedBar._id));
            //setIsWished(user.wishBars.includes(selectedBar._id));
        }
    }, [authenticated, user, selectedBar._id]);

    const handleBarAction = useCallback(async (action, setter) => {
        if (!authenticated) {
            setShowNotAuth(true);
            return;
        }
        try {
            const response = await api.users[action]({ userId: user._id, barId: selectedBar._id });
            if (response.status === 200) {
                setter(prev => !prev);

                // Clonamos los arrays antes de actualizar
                let updatedFavoriteBars = [...user.favoriteBars];
                let updatedWishBars = [...user.wishBars];

                // Actualizamos el array correspondiente dependiendo de la acción
                if (action === 'addFavoriteBar') {
                    updatedFavoriteBars = [...updatedFavoriteBars, selectedBar._id];
                } else if (action === 'removeFavoriteBar') {
                    updatedFavoriteBars = updatedFavoriteBars.filter(barId => barId !== selectedBar._id);
                } else if (action === 'addWishBar') {
                    updatedWishBars = [...updatedWishBars, selectedBar._id];
                } else if (action === 'removeWishBar') {
                    updatedWishBars = updatedWishBars.filter(barId => barId !== selectedBar._id);
                }

                const newUser = {
                    ...user,
                    favoriteBars: updatedFavoriteBars,
                    wishBars: updatedWishBars,
                };

                // Imprime el nuevo objeto user para verificarlo
                console.log("Nuevo objeto user que se enviará a guardarUser:", newUser);

                // Guardamos el usuario actualizado en el estado global
                guardarUser(newUser);

            }
        } catch (error) {
            console.error(`Error ${action}:`, error);
        }
    }, [authenticated, user, selectedBar._id]);

    const handleLikeClick = useCallback(() => handleBarAction(isLiked ? 'removeFavoriteBar' : 'addFavoriteBar', setIsLiked), [handleBarAction, isLiked]);
    //const handleSaveClick = useCallback(() => handleBarAction(isWished ? 'removeWishBar' : 'addWishBar', setIsWished), [handleBarAction, isWished]);

    const handleCloseClick = useCallback(() => setSelectedBar(null), [setSelectedBar]);
    const handleBarDetallesClick = useCallback(() => navigate(`/bar/${selectedBar._id}`), [navigate, selectedBar]);


    const memoizedStarRating = useMemo(() => <StarRating stars={selectedBar.rating} />, [selectedBar.rating]);
    const memoizedTipoEtiquetas = useMemo(() => 
        selectedBar.types.slice(0, 2).map((type, index) => <TipoEtiqueta key={index} tipo={type} />),
        [selectedBar.types]
    );

    useEffect(() => {
        // This effect will run after the component mounts
        const hideDefaultCloseButton = () => {
          const closeButtons = document.querySelectorAll('.gm-ui-hover-effect');
          closeButtons.forEach(button => {
            button.style.display = 'none';
          });
        };
    
        // Hide the button immediately and also after a short delay to ensure it's hidden
        hideDefaultCloseButton();
        setTimeout(hideDefaultCloseButton, 100);
      }, []);

    return (
    <InfoWindowF
        position={selectedBar.location}
        onCloseClick={handleCloseClick}
        options={{ 
            pixelOffset: new window.google.maps.Size(0, -30)
        }}
    >
        <div className="info-window-modern" onClick={handleBarDetallesClick}>
            <Button
                isIconOnly
                color="default"
                variant="light"
                onClick={(e) => { e.stopPropagation(); handleCloseClick(); }}
                aria-label="Close"
                className="close-button"
                >
                <FontAwesomeIcon icon={faTimes} size="lg"/>
            </Button>
            <div className="info-content">
                <div className="info-header"> 
                    <h3 className="bar-name">{selectedBar.name}</h3>
                    <Button 
                        isIconOnly 
                        color="danger" 
                        variant="light" 
                        onClick={(e) => { e.stopPropagation(); handleLikeClick(); }}
                        aria-label={isLiked ? "Unlike" : "Like"} 
                        className="action-button like-button"
                    >
                        <FontAwesomeIcon icon={isLiked ? solidHeart : regularHeart} size="lg" />
                    </Button>
                </div>
                <div className='bar-details'>
                    <div className="flex items-center text-small text-foreground/80">
                        <Star className="w-4 h-4 mr-1 text-yellow-400" />
                        <span>{'(' + utils.formato1Decimal(selectedBar.rating) + ') '}{memoizedStarRating}</span>
                    </div>
                    <div className="flex items-start">
                        <Euro className="w-4 h-4 mr-1" />
                        <span className="text-xs text-foreground/70">
                        {selectedBar.priceLevel ? 'Precio: ' + '€'.repeat(selectedBar.priceLevel) : 'Precio no disponible'}
                        </span>
                    </div>
                    <div className="flex items-start">
                        <MapPin className="w-4 h-4 mr-1 mt-1 flex-shrink-0" />
                        <span className="text-xs text-foreground/70 line-clamp-2">
                        {utils.getAddres(selectedBar.address)}
                        </span>
                    </div>
                </div>
            </div>
            
            <div className="info-tags">
                {memoizedTipoEtiquetas}
            </div>

            <Toast onClose={() => setShowNotAuth(false)} show={showNotAuth} delay={3000} autohide>
                <ToastHeader>
                    <strong className="me-auto">No estás registrado</strong>
                </ToastHeader>
                <ToastBody>Debes iniciar sesión para realizar esta acción</ToastBody>
            </Toast>

        </div>
    </InfoWindowF>
  );
};

export default BarInfoPopUp;