class UserFiltrosDto {
    constructor(userLocation,zoom, tastesAndPreferences, filtros, polygon = []) {
        this.userLocation = userLocation;
        this.zoom = zoom;
        this.tastesAndPreferences = tastesAndPreferences;
        this.filtros = filtros;
        this.polygon = polygon;  
    }
}

export default UserFiltrosDto;
