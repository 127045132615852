import { Avatar } from '@nextui-org/react';
import React, { useEffect, useState } from 'react'
import classNames from 'classnames';

const IconosTemas = ({ tema, onTemaClick, opinionesDelBar }) => {
  const { img, title, id:nombreAbreviado } = tema;
  const [seleccionado, setSeleccionado] = useState(false);


  useEffect(() => {
    // Si opinionesDelBar es válido y tiene tags, comprobar si el 'name' está presente
    console.log("IconosTemas", opinionesDelBar);
    

    if (opinionesDelBar?.tags) {
      console.log("IconosTemas", opinionesDelBar?.tag);
      console.log("IconosTemas", nombreAbreviado);
      const encontrado = opinionesDelBar.tags.some(tag => tag.name === nombreAbreviado);
      setSeleccionado(encontrado);
    } else {
      setSeleccionado(false); // Si no hay opinionesDelBar, no está seleccionado
    }
  }, [opinionesDelBar, nombreAbreviado]);

  const handleClick = () => {
    setSeleccionado(!seleccionado);
    onTemaClick(tema);
  };

  const iconClass = classNames('icono-tema', {
    'seleccionado': seleccionado,
  });

  return (
     <div className={iconClass} onClick={handleClick}>
      <div className="icono-tema__avatar">
        <Avatar src={img} size="lg" className='avatar_img'/>
      </div>
      <b className="icono-tema__title">{title}</b>
    </div>
  );
}

export default IconosTemas;